import React, {lazy, Suspense, useEffect, useMemo, useState} from "react";
import {validRouting} from "./Utils/validRouting";

function App() {
  const [allowed, setAllowed] = useState(false);

  useEffect(() => {
    if (validRouting()) {
      setAllowed(true);
    }
  }, []);

  const AppLayout = useMemo(() => {
    if (!allowed) {
      return lazy(() => import("./Views/auth/NotAvailable"));
    }
    return lazy(() => import("AppProvider"));
  }, [allowed]);


  return (
    <Suspense fallback={<div className="flex flex-auto flex-col h-[100vh]"/>}>
      <div className="subpixel-antialiased">
        <AppLayout/>
      </div>
    </Suspense>
  );
}

export default App;